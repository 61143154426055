import React, { useState, useEffect } from 'react';
import { Carousel,  Container } from 'react-bootstrap';
import './banner.css'

function Banner() {  
    const [imageUrls, setImageUrls] = useState([]);


    useEffect(() => {
        const imageRefs = [
            '/images/homepage/banner/4.jpg',
            '/images/homepage/banner/2.jpg',
            '/images/homepage/banner/3.jpg',
            '/images/homepage/banner/1.jpg',
            '/images/homepage/banner/banner1.png',
            '/images/homepage/banner/banner2.png',
            '/images/homepage/banner/banner3.png',
            '/images/homepage/banner/banner4.png',
        ];
            setImageUrls(imageRefs);
    }, []);

  return (
    <Container fluid className="carousel-container"   >
        {/* <Row>
            <Col md="9"> */}
            <Carousel slide='true' fade='true' >
        {imageUrls.map((url, index) => (
            <Carousel.Item key={index}>
                <a href="https://book2.bigwindvi.com/2001/07qh3/index.html" target="_blank" rel="noopener noreferrer">
                <img src={url} alt={`Slide ${index + 1}`}  /></a>
            </Carousel.Item>
        ))}
    </Carousel>
            {/* </Col>
            <Col md="3">
                
            </Col>
        </Row> */}
      
    </Container>
    
  );
}

export default Banner;
